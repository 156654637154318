import React from 'react';
import { navigate } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import * as styles from './404.module.scss';
import Layout from '../components/Layout';

const ErrorPage = () => {
    return (
        <Layout darkBg={false}>
            <div className={styles.errorContainer}>
                <div className={styles.imageContainer}>
                    <StaticImage
                        src="../images/error.jpg"
                        alt="Error"
                        className={styles.image}
                        placeholder="blurred"
                        objectFit="contain"
                    />
                </div>

                <div className={styles.blueBtn} onClick={() => navigate(-1)}>
                    Go back
                </div>
            </div>
        </Layout>
    );
};

export default ErrorPage;
